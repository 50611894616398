<template>
  <el-dialog
      title=""
      :visible.sync="show"
      width="40%"
      :before-close="handleClose">
    <avue-form ref="form"
               v-model="form"
               :option="formOption"
               @submit="submit">
      <template slot="menuForm">
        <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
      </template>
    </avue-form>
  </el-dialog>
</template>

<script>
export default {
  name: "examine",
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    // 带审核组件所需要参数
    // 审核请求接口
    request: {
      type: Function,
      default: null
    },
    // 审核请求接口ID
    examineId: {
      type: String,
      default: ()=> ''
    }
  },
  data(){
    return{
      form: {
        businessState: 3
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '审核',
            prop: 'businessState',
            type: 'radio',
            value: 2,
            dicData: [
              { label: '审核通过', value: 3 },
              { label: '审核驳回', value: 4 }
            ],
            span: 24,
            rules: [{
              required: true,
              message: "请选择审核状态",
              trigger: "blur"
            }],
            change: (e) => {
              const rejectReason = this.findObject(this.formOption.column,'businessStateReason')
              rejectReason.display = e.value !== 3;
            }
          },
          {
            label: '',
            prop: 'businessStateReason',
            type: 'textarea',
            maxlength: 30,
            showWordLimit: true,
            span: 24,
            rules: [{
              required: true,
              message: "请输入驳回原因",
              trigger: "blur"
            }],
            display: false
          }
        ]
      },
    }
  },
  methods: {
    submit(form,done){
      // // console.log(form)
      this.request({
        id: this.examineId,
        ...form
      }).then(res=> {
        if(res.code === 200){
          this.$message.success('操作成功')
          this.$emit('close',true)
        }else{
          this.$message.error(res.msg)
          done()
        }
      }).catch(()=>done())
    },
    handleClose(){
      this.$emit('close',false)
    }
  }
}
</script>

<style scoped>

</style>